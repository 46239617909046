export const server = "https://ntarasiplaycouples.com/ntarasiapis";
export const inviteUrl = "https://ntarasiplaycouples.com";
export const assetsBackgrounds = "../assets/backgrounds/";
export const assetsgraphics = "../assets/graphics/";
export const nodeServer = "https://nodebackened.ntarasiplaycouples.com";

// export const server = "http://localhost/ntarasiapis";
// export const inviteUrl = "http://localhost:3000";
// export const assetsBackgrounds = "../assets/backgrounds/";
// export const assetsgraphics = "../assets/graphics/";
// export const nodeServer = "http://localhost:3003";
