import React from "react";
import backPhoto from "../assets/graphics/back.jpg";

function About() {
  const backgroundStyle = {
    backgroundImage: `url(${backPhoto})`,
  };
  return (
    <div style={backgroundStyle}>
      <h3>About This Game</h3>
      <div className="introtext">
        NtarasiPlay is an exciting, interactive Mobile Game that enhances
        communication and intimacy between couples. Friends get to know each
        other better <br />
        Enjoy the game. <br />
        NtarasiPlay is the perfect date! <br />
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-sm-12"
              style={{
                overflow: "scroll",
                textAlign: "left",
                marginLeft: "10px",
                //height: "100vh",
                // width: "100%",
                color: "black",
              }}
            >
              <hr />
              <h3>Instructions (Scroll to the end):</h3>
              1. This game is played by two people. <br />
              2. You can play this game with a partner who is not physically
              near you. <br />
              3. Each player uses their own gadget <br />
              4. One player starts the game by Clicking on the Ntarasiplay link
              <br />
              5. Click on Ntarasiplay App is out. Check it out <br />
              6. Click on proceed <br />
              7. Click Play online <br />
              8. Select the category you want by clicking on it <br />
              9. Set your profile by choosing your gender, pseudo/ real name,
              and your avatar <br />
              10. Invite your partner by Clicking on WhatsApp /email <br />
              11. Choose the WhatsApp contact of your partner and send it to
              your partner <br />
              12. Wait for your partner to join the game <br />
              13. You will be directed to the board, level 1 <br />
              14. Click on Next card to continue the conversation <br />
              15. Respond to these by typing your responses on the chat <br />
              16. To exit the game, click on the exit button at the top right
              corner of your gadget <br />
              17. Every category has several levels. <br />
              18. When you finish your category, start another category by
              clicking on one of buttons.
              <br />
              19. While playing the game, please be respectful, kind and loving
              towards your partner. <br />
              20. Be real, so that your partner can understand you better <br />
              21. Don't judge or ridicule your partner's choices. <br />
              22. If you exit game, you can go back by clicking the invite link{" "}
              <br />
              23. If game hangs click on refresh button to refresh game. <br />
              <br /> <p style={{ color: "green" }}>Game Reviews: </p>
              1. 'We feel so much closer whenever we play this game' Chris and
              Doreen <br />
              2. 'This game has really done something special for my husband and
              I. We have really bonded' Martin and Karen <br />
              3. ' I knew this game was epic, when I played it with my Boo after
              a big disagreement and we started connecting again' Anne and Ag.{" "}
              <br />
              4. 'This game has allowed us to discuss interesting we usually
              wouldn't discuss. The conversation is amazing ' Sarah and Levi{" "}
              <br />
              5. 'This game has really improved our sex life' Eric and Cate{" "}
              <br />
              6. 'I enjoy playing this game with my sweetheart. It helps me to
              understand her more' Evans and Jane <br />
            </div>
          </div>
        </div>
      </div>
      <div className="row fixed-bottom">
        <div className="col-lg-12 text-center"></div>
      </div>
    </div>
  );
}

export default About;
